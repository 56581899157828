<template>
  <div>
    <Header v-if="$route.fullPath!=='/product-instructions'" />
    <router-view></router-view>
    <Footer />
    <Sidebar />
  </div>
</template>

<script>
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'
import Sidebar from './components/Sidebar.vue'

export default {
  components: { Header, Footer, Sidebar },
}
</script>

